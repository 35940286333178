import React from 'react';
import LargeSigmaLogo from '../../components/LargeSigmaLogo';
import LoadingScreen from '../../components/LoadingScreen';
import SplitPane from '../../layout/SplitPane';
import OrganizationForm from './OrganizationForm';
import '../../scss/_organization.scss';

const Organization = () => {
  return (
    <LoadingScreen>
      <SplitPane left={<LargeSigmaLogo />} right={<OrganizationForm />} />
    </LoadingScreen>
  );
};

export default Organization;
