import React from 'react';
import { Row, Col } from 'react-bootstrap';

type Props = { left: JSX.Element; right: JSX.Element };

const SplitPane = ({ left, right }: Props) => {
  return (
    <Row className="d-flex align-items-center h-100 w-100 no-gutters">
      <Col xs={12} lg={6} className="d-flex justify-content-center">
        {left}
      </Col>
      <Col xs={12} lg={6} className="d-flex justify-content-start  pr-0 pr-md-5">
        <div className="right-pane">{right}</div>
      </Col>
    </Row>
  );
};

export default SplitPane;
