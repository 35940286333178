import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import CreditCard from './pages/CreditCart/CreditCard';
import Home from './pages/Home/Home';
import Organization from './pages/Organization/Organization';
import OrganizationProfile from './pages/OrganizationProfile/OrganizationProfile';
import Address from './pages/Address/Address'
import Success from './pages/Success/Success';
import { LoadingProvider } from './utils/LoadingProvider';

function App() {
  return (
    <LoadingProvider>
      <Router>
        <Switch>
          <Route exact path="/organization" component={Organization} />
          <Route exact path="/address" component={Address} />
          <Route
            exact
            path="/organization-profile"
            component={OrganizationProfile}
          />
          <Route exact path="/credit-card" component={CreditCard} />
          <Route exact path="/success" component={Success} />
          <Route exact path="/" component={Home} />
          <Redirect to="/" />
        </Switch>
      </Router>
    </LoadingProvider>
  );
}

export default App;
