export function toEin(phone: string) {
  return `+1${phone.replace(/[^0-9]/g, '')}`;
}

export const toJSON = (response: Response) => {
  if (response.ok) return response.json();
  else throw response;
};

export interface OrganizationOptions {
  id: string;
  name: string;
  key: string;
  order: number;
  created_at: string;
  updated_at: string;
  url: string;
}

export type ProfileOptions = {
  goal_options: OrganizationOptions[];
  industry_options: OrganizationOptions[];
  role_options: OrganizationOptions[];
  size_options: OrganizationOptions[];
};

export const inboundTexts = [
  'Immediately build relationships with inbound leads to close more deals',
  'Use text, email, and phone to create multichannel engagement',
  'Fits effortlessly into your existing sales process',
  'Create incredible new customer experiences',
  'Supercharge your sales team',
];

export const outboundTexts = [
  'Create effortless multichannel follow-up with phone, text, and email',
  'Automate the grunt work to spend more time closing deals',
  'Customize your messaging to fit your brand voice',
  'Start using your customer/lead lists as a revenue generation machine',
  'Supercharge your sales team',
];

export type Status = 'inbound' | 'outbound';
// if more plans are added in future, add enums here
export const STARTUP = 'STARTUP';

export type User = null | {
  firstName: string;
  lastName: string;
  email: string;
};
