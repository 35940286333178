import React from 'react';
import purpleBlob from '../../images/purple-blob.svg';

const PurpleBlob = () => {
  return (
    <div className="blob-container">
      <img src={purpleBlob} className="purple-blob blob-1" />
      <img src={purpleBlob} className="purple-blob blob-2" />
      <img src={purpleBlob} className="purple-blob blob-3" />
    </div>
  );
};

export default PurpleBlob;
