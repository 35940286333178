import Cookies from 'js-cookie';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Container, Row, Col, Form, Alert, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useLoadingContext } from '../../utils/LoadingProvider';
import { ProfileOptions, toJSON } from '../../utils/utils';

const OrganizationProfileForm = () => {
  const { setIsLoading } = useLoadingContext();
  const history = useHistory();
  const [profileOptions, setProfileOptions] = useState<ProfileOptions | null>();
  const [selectedOptions, setSelectedOptions] = useState({
    industry_id: '',
    company_size_id: '',
    company_goal_id: '',
    company_role_id: '',
  });
  const [error, setError] = useState<string | null>(null);
  useEffect(() => {
    setIsLoading(true);
    fetch(`${process.env.REACT_APP_API_BASE}/organization_profile_options`, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
      },
    })
      .then(toJSON)
      .then((data) => {
        setIsLoading(false);
        setProfileOptions(data);
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.body) {
          error.json().then((message: any) => {
            Object.values(message).forEach((value) => setError(value as string));
          });
        } else
          setError('There was an error contacting the server. Please try again.');
      });
  }, []);

  const submitOrgProfile = (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    fetch(`${process.env.REACT_APP_API_BASE}/organization_profile`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        Accept: 'application/json',
      },
      body: JSON.stringify(selectedOptions),
    })
      .then(toJSON)
      .then((data) => {
        history.push('/credit-card');
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.body)
          error.json().then((message: any) => {
            Object.values(message).forEach((value) => setError(value as string));
          });
        else setError('There was an error contacting the server. Please try again.');
      });
  };

  return (
    <Container fluid className="w-100 h-100 d-flex justify-content-center">
      <Row noGutters>
        <Col xs={12}>
          <h2 className="text-black">Setup Organization</h2>
        </Col>
        <Col xs={12} className="mb-5">
          <p className="text-grey">
            LeadSigma sets up an account based on your organization
          </p>
        </Col>
        <Alert
          variant="danger"
          show={!!error}
          onClose={() => setError(null)}
          dismissible
          className="w-100"
        >
          <div>{error}</div>
          <a href={process.env.REACT_APP_LEADSIGMA_SUPPORT}>LeadSigma Support</a>
        </Alert>
        <Col xs={12}>
          <Form onSubmit={submitOrgProfile}>
            <Form.Group>
              <h5 className="text-black">What is your industry?</h5>
              <Form.Control
                as="select"
                className="grey-bordered-box form-text-input mb-3"
                placeholder="Industry"
                required
                onChange={(e) =>
                  setSelectedOptions((prev) => ({
                    ...prev,
                    industry_id: e.target.value,
                  }))
                }
              >
                <option key="blankChoice" hidden value={''}></option>
                {profileOptions?.industry_options.map((profileOption) => (
                  <option key={profileOption.id} value={profileOption.id}>
                    {profileOption.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <h5 className="text-black">How big is your company?</h5>
              <Form.Control
                as="select"
                className="grey-bordered-box form-text-input mb-3"
                required
                onChange={(e) =>
                  setSelectedOptions((prev) => ({
                    ...prev,
                    company_size_id: e.target.value,
                  }))
                }
              >
                <option key="blankChoice" hidden value={''}></option>
                {profileOptions?.size_options.map((profileOption) => (
                  <option key={profileOption.id} value={profileOption.id}>
                    {profileOption.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <h5 className="text-black">What is your role in the company?</h5>
              <Form.Control
                as="select"
                className="grey-bordered-box form-text-input mb-3"
                required
                onChange={(e) =>
                  setSelectedOptions((prev) => ({
                    ...prev,
                    company_role_id: e.target.value,
                  }))
                }
              >
                <option key="blankChoice" hidden value={''}></option>
                {profileOptions?.role_options.map((profileOption) => (
                  <option key={profileOption.id} value={profileOption.id}>
                    {profileOption.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <h5 className="text-black">
                What is your company&apos;s next big goal?
              </h5>
              <Form.Control
                as="select"
                className="grey-bordered-box form-text-input mb-3"
                required
                onChange={(e) =>
                  setSelectedOptions((prev) => ({
                    ...prev,
                    company_goal_id: e.target.value,
                  }))
                }
              >
                <option key="blankChoice" hidden value={''}></option>
                {profileOptions?.goal_options.map((profileOption) => (
                  <option key={profileOption.id} value={profileOption.id}>
                    {profileOption.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Button className="btn-green next-button mt-5 float-right" type="submit">
              <h5 className="text-white align-middle m-0">Next</h5>
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default OrganizationProfileForm;
