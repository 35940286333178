import React, { Fragment } from 'react';
import { Container, Row } from 'react-bootstrap';
import ClipLoader from 'react-spinners/ClipLoader';
import { useLoadingContext } from '../utils/LoadingProvider';

const LoadingScreen = ({ children }: { children: React.ReactNode }) => {
  const { isLoading } = useLoadingContext();
  return (
    <Container fluid className="progress-container-border p-0 pb-5">
      <Row
        className={`w-100 h-100 ${
          !isLoading ? 'd-none' : 'd-flex'
        } justify-content-center align-items-center`}
      >
        <ClipLoader size="30vw" color="#34d1b6" />
      </Row>
      <Container
        fluid
        className={`${isLoading ? 'd-none' : 'd-block'} w-100 h-100 p-0 m-0`}
      >
        {children}
      </Container>
    </Container>
  );
};

export default LoadingScreen;
