import React from 'react';
import sigmaLogo from '../images/sigma-logo.svg';
import table from '../images/large-table.svg';

const LargeTrialImage = () => {
  return (
    <div className="h-100 w-100">
      <div className="trial-banner-container mt-2 mb-2 mb-lg-5">
        <div className="trial-banner d-flex align-items-center">
          <h2 className="text-white text-right mb-0">21 DAY FREE TRIAL</h2>
        </div>
      </div>
      <div className="d-flex flex-column justify-content-center align-items-center h-100 w-100">
        <img src={table} alt="Coworkers Around Table" className="large-image" />
        <div className="d-flex justify-content-between">
          <div className="purple-box ml-2">
            <p className="text-white m-0 text-center">$299 a Month</p>
          </div>
        </div>
      </div>
      <div className="sigma-logo-container d-none d-lg-block">
        <img src={sigmaLogo} alt="Lead Sigma Logo" className="sigma-logo" />
      </div>
    </div>
  );
};

export default LargeTrialImage;
