import React from 'react';
import LargeSigmaLogo from '../../components/LargeSigmaLogo';
import LoadingScreen from '../../components/LoadingScreen';
import SplitPane from '../../layout/SplitPane';
import AddressForm from './AddressForm';
import '../../scss/_organization.scss';

const Organization = () => {
  return (
    <LoadingScreen>
      <SplitPane left={<LargeSigmaLogo />} right={<AddressForm />} />
    </LoadingScreen>
  );
};

export default Organization;
