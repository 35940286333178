import React from 'react';
import { Button, Form, Row, Col, Container, Alert } from 'react-bootstrap';
import { useState } from 'react';
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai';
import passwordValidator from 'password-validator';
import { useEffect } from 'react';
import Cookies from 'js-cookie';
import { useHistory, useLocation } from 'react-router-dom';
import {
  CountryCode,
  isValidNumber,
  parsePhoneNumber,
  PhoneNumber,
} from 'libphonenumber-js';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useLoadingContext } from '../../utils/LoadingProvider';
import { toJSON, User } from '../../utils/utils';
import { getHubspotCodeCookie } from '../../utils/HubspotCode';
import { parse } from 'query-string';

const schema = new passwordValidator();
schema
  .is()
  .min(8)
  .has()
  .uppercase()
  .has()
  .lowercase()
  .has()
  .digits(1)
  .has(/(?=.*[\^$*.\[\]{}\(\)?\-"!@#%&/,><\':;|_~`+=\\])/);

interface CountryData {
  name: string;
  dialCode: string;
  countryCode: string;
  format: string;
}

const SignUpForm = () => {
  const { setIsLoading, isLoading } = useLoadingContext();

  const [
    {
      firstName,
      lastName,
      email,
      phonePrefix,
      phoneNumber,
      password,
      confirmPassword,
    },
    setFormState,
  ] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phonePrefix: '',
    phoneNumber: '',
    password: '',
    confirmPassword: '',
  });
  const location = useLocation();
  const history = useHistory();
  const [errors, setErrors] = useState<string[]>([]);
  const [passwordValidations, setPasswordValidations] = useState<string[]>([]);
  const [doPasswordsMatch, setDoPasswordsMatch] = useState(false);
  const [showPasswordHints, setShowPasswordHints] = useState(false);
  const [phoneIsValid, setPhoneIsValid] = useState(false);
  const [showConfirmPasswordHint, setShowConfirmPasswordHint] = useState(false);
  const [passwordFocused, setPasswordFocused] = useState(false);
  const [confirmPasswordFocused, setConfirmPasswordFocused] = useState(false);
  const [parsedPhone, setParsedPhone] = useState('');

  useEffect(() => {
    if (isLoading) setIsLoading(false);

    let test = parse(window.location.search).test;
    const alwaysTest = process.env.REACT_APP_ALWAYS_TEST as string;
    if (test == null || test?.length == 0) test = alwaysTest;
    localStorage.setItem('test', test as string);
  }, []);

  useEffect(() => {
    setPasswordValidations(getPasswordValidations());
    setDoPasswordsMatch(getPasswordMatch());
  }, [password, confirmPassword]);

  const submitForm = (e: React.FormEvent) => {
    e.preventDefault();
    setErrors([]);
    setPasswordFocused(true);
    setConfirmPasswordFocused(true);

    if (!hasValidPassword()) {
      setErrors((prev) => [
        ...prev,
        'Please make sure your password matches the required format.',
      ]);
    }
    if (confirmPassword !== password)
      setErrors((prev) => [...prev, 'Please make sure your passwords match.']);
    if (!phoneIsValid)
      setErrors((prev) => [...prev, 'Please enter a valid phone number.']);

    if (hasValidPassword() && confirmPassword === password && phoneIsValid) {
      // submit signup form if conditions met
      setIsLoading(true);
      const reqBody: {
        first_name: string;
        last_name: string;
        email_address: string;
        phone_number_prefix: string;
        phone_number: string;
        password: string;
        test?: string;
      } = {
        first_name: firstName.trim(),
        last_name: lastName.trim(),
        email_address: email.trim(),
        phone_number_prefix: '+' + phonePrefix,
        phone_number: parsedPhone,
        password: password,
        test: localStorage.getItem('test') ?? '',
      };

      fetch(`${process.env.REACT_APP_API_BASE}/create_initial_user`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },

        body: JSON.stringify(reqBody),
      })
        .then(toJSON)
        .then((data: any) => {
          if (!data.access_token) {
            setIsLoading(false);
            setErrors((prev) => [...prev, 'There was an error creating your user.']);
          } else {
            const user: User = {
              firstName: firstName.trim(),
              lastName: lastName.trim(),
              email: email.trim(),
            };
            localStorage.setItem('userOrg', data.organization_id);
            localStorage.setItem('user', JSON.stringify(user));
            Cookies.set('cognito-jwt', data.access_token, {
              domain: window.location.hostname.replace(/^[^.]+\./g, ''),
            });
            history.push('/organization');
          }
        })
        .catch((error) => {
          setIsLoading(false);
          if (error.body)
            error.json().then((message: any) => {
              setErrors((prev) => [...prev, message.error]);
            });
          else
            setErrors((prev) => [
              ...prev,
              'There was an error contacting the server. Please try again.',
            ]);
        });
    }
  };

  const hasValidation = (validation: string) =>
    !passwordValidations.includes(validation);

  const hasValidPassword = () => schema.validate(password);

  const getPasswordValidations = () => {
    const invalids = schema.validate(password, { list: true });
    setShowPasswordHints(invalids.length > 0);
    setPasswordValidations(invalids);
    return invalids;
  };

  const getPasswordMatch = () => {
    setShowConfirmPasswordHint(password !== confirmPassword);
    return password === confirmPassword;
  };

  const getParsedPhone = (phone: string, data: CountryData): PhoneNumber | null => {
    let parsed = null;
    try {
      parsed = parsePhoneNumber(
        phone,
        data.countryCode.toUpperCase() as CountryCode
      );
    } catch (e) {
      parsed = null;
    }
    if (!parsed) return null;

    return parsed;
  };

  const hasValidPhone = (parsed: PhoneNumber | null) => {
    if (parsed)
      return isValidNumber(
        parsed.nationalNumber as string,
        parsed.country as CountryCode
      );
    else return false;
  };

  const PasswordHints = () => {
    if (showPasswordHints && passwordFocused)
      return (
        <div className="password-validations mt-1">
          <div className="mx-1 d-flex align-items-center">
            {hasValidation('min') ? (
              <AiOutlineCheck color="#34d1b6" />
            ) : (
              <AiOutlineClose color="red" />
            )}
            <div>8 Characters</div>
          </div>
          <div className="mx-1 d-flex align-items-center">
            {hasValidation('uppercase') ? (
              <AiOutlineCheck color="#34d1b6" />
            ) : (
              <AiOutlineClose color="red" />
            )}
            <div> Uppercase Letter</div>
          </div>
          <div className="mx-1 d-flex align-items-center">
            {hasValidation('lowercase') ? (
              <AiOutlineCheck color="#34d1b6" />
            ) : (
              <AiOutlineClose color="red" />
            )}
            <div>Lowercase Letter</div>
          </div>
          <div className="mx-1 d-flex align-items-center">
            {hasValidation('has') ? (
              <AiOutlineCheck color="#34d1b6" />
            ) : (
              <AiOutlineClose color="red" />
            )}
            <div> Special Character</div>
          </div>

          <div className="mx-1 d-flex align-items-center">
            {hasValidation('digits') ? (
              <AiOutlineCheck color="#34d1b6" />
            ) : (
              <AiOutlineClose color="red" />
            )}
            <div> Number</div>
          </div>
        </div>
      );
    return null;
  };

  const ConfirmPasswordHint = () => {
    if (showConfirmPasswordHint && confirmPasswordFocused)
      return (
        <div className="password-validations mt-1">
          <div className="mx-1 d-flex align-items-center">
            {doPasswordsMatch ? (
              <AiOutlineCheck color="#34d1b6" />
            ) : (
              <AiOutlineClose color="red" />
            )}
            <div>Passwords Match</div>
          </div>
        </div>
      );
    return null;
  };

  return (
    <Container fluid className="w-100 h-100 d-flex justify-content-center">
      <Form onSubmit={submitForm} className="w-100">
        <h1 className="text-black mb-5">Sign Up</h1>
        <Row noGutters className="signup-form-fields">
          <Alert
            show={errors.length > 0}
            variant="danger"
            onClose={() => setErrors([])}
            dismissible
            className="w-100"
          >
            {errors?.map((error, i) => (
              <p className="m-0" key={i}>
                {error}
              </p>
            ))}
            <a
              href={process.env.REACT_APP_LEADSIGMA_SUPPORT}
              target="_blank"
              rel="noopener noreferrer"
            >
              LeadSigma Support
            </a>
          </Alert>
          <Form.Group as={Col} xs={6} controlId="firstName" className="pr-2">
            <Form.Control
              className="grey-bordered-box form-text-input"
              type="text"
              name="firstName"
              placeholder="First Name"
              autoComplete="given-name"
              value={firstName}
              maxLength={100}
              onChange={(e) => {
                setFormState((prev) => ({
                  ...prev,
                  firstName: e.target.value,
                }));
              }}
              required
            />
          </Form.Group>
          <Form.Group as={Col} xs={6} controlId="lastName" className="pl-2">
            <Form.Control
              className="grey-bordered-box form-text-input"
              type="text"
              placeholder="Last Name"
              autoComplete="family-name"
              name="lastName"
              value={lastName}
              maxLength={100}
              onChange={(e) => {
                setFormState((prev) => ({
                  ...prev,
                  lastName: e.target.value,
                }));
              }}
              required
            />
          </Form.Group>
          <Form.Group as={Col} xs={12} controlId="email">
            <Form.Control
              className="grey-bordered-box form-text-input"
              type="email"
              placeholder="Email"
              autoComplete="email"
              name="email"
              required
              value={email}
              maxLength={100}
              onChange={(e) => {
                setFormState((prev) => ({
                  ...prev,
                  email: e.target.value,
                }));
              }}
            />
          </Form.Group>
          <Form.Group as={Col} xs={12} controlId="phoneNumber">
            <PhoneInput
              autocompleteSearch={true}
              disableCountryGuess={true}
              disableCountryCode={true}
              placeholder={'Phone Number'}
              inputProps={{ required: true }}
              containerClass="grey-bordered-box form-text-input w-100 phone-input-container"
              inputClass="phone-input"
              country={'us'}
              value={phoneNumber}
              onChange={(phone, data: CountryData) => {
                const parsed = getParsedPhone(phone, data);
                setParsedPhone(parsed?.nationalNumber as string);
                setFormState((prev) => ({
                  ...prev,
                  phoneNumber: phone,
                  phonePrefix: data.dialCode,
                }));
                setPhoneIsValid(hasValidPhone(parsed));
              }}
            />
          </Form.Group>
          <Form.Group as={Col} xs={12} controlId="password">
            <Form.Control
              className="grey-bordered-box form-text-input"
              type="password"
              placeholder="Password"
              autoComplete="new-password"
              required
              value={password}
              onChange={(e) => {
                setFormState((prev) => ({
                  ...prev,
                  password: e.target.value.split(' ').join(''),
                }));
              }}
              onFocus={() => setPasswordFocused(true)}
              maxLength={100}
            />
            <PasswordHints />
          </Form.Group>
          <Form.Group as={Col} xs={12} controlId="confirmPassword">
            <Form.Control
              className="grey-bordered-box form-text-input"
              type="password"
              placeholder="Re-Enter Password"
              autoComplete="new-password"
              name="confirmPassword"
              value={confirmPassword}
              onChange={(e) => {
                setFormState((prev) => ({
                  ...prev,
                  confirmPassword: e.target.value.split(' ').join(''),
                }));
              }}
              onFocus={() => setConfirmPasswordFocused(true)}
              required
              maxLength={100}
            />
            <ConfirmPasswordHint />
          </Form.Group>
        </Row>
        <Button type="submit" className="btn-green next-button mt-3">
          Create Account
        </Button>
      </Form>
    </Container>
  );
};

export default SignUpForm;
