import Cookies from 'js-cookie';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Alert, Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import TimezoneSelect, { ITimezoneOption } from 'react-timezone-select';
import { useLoadingContext } from '../../utils/LoadingProvider';
import { toJSON } from '../../utils/utils';
import { getHubspotCodeCookie } from '../../utils/HubspotCode';
import { getCountries } from 'libphonenumber-js';
import { countries } from 'countries-list';

const OrganizationForm = () => {
  const { setIsLoading } = useLoadingContext();
  const history = useHistory();
  const [hubCode, setHubCode] = React.useState<string | undefined>();
  const [orgName, setOrgName] = useState('');
  const [timezone, setTimezone] = useState('');
  const [country, setCountry] = useState('US');
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    setIsLoading(false);
    const code: string | undefined = getHubspotCodeCookie();
    if (code) setHubCode(code);
  }, []);

  const renderCountries = () => {
    return Object.keys(countries).map(function (key: string) {
      return (
        <option id={key} key={key} value={key}>
          {key}
        </option>
      );
    });
  };

  const submitOrganization = (e: React.FormEvent) => {
    setError(null);
    e.preventDefault();

    if (timezone === '') setError('Please select your timezone.');
    else {
      setError(null);
      setIsLoading(true);
      let body: {
        organization_name: string;
        timezone: string;
        hubspot_auth_code?: string;
      } = { organization_name: orgName.trim(), timezone };
      if (hubCode) body = { ...body, hubspot_auth_code: hubCode };

      fetch(`${process.env.REACT_APP_API_BASE}/setup_organization`, {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
        },
        body: JSON.stringify(body),
      })
        .then(toJSON)
        .then((data) => {
          localStorage.setItem('country', country.trim());
          history.push('/address');
        })
        .catch((error) => {
          setIsLoading(false);
          if (error.body)
            error.json().then((message: any) => {
              Object.values(message).forEach((value) => setError(value as string));
            });
          else
            setError('There was an error contacting the server. Please try again.');
        });
    }
  };

  const mapTimeZone = (timezone: string): string => {
    if (timezone == 'America/Boise') {
      return 'America/Denver';
    } else if (timezone == 'America/Detroit') {
      return 'America/New_York';
    } else if (timezone == 'America/Tijuana') {
      return 'America/Los_Angeles';
    }
    return timezone;
  };

  return (
    <Container fluid className="w-100 h-100 d-flex justify-content-start mt-5">
      <Row noGutters>
        <Col xs={12}>
          <h2 className="text-black">Setup Organization</h2>
        </Col>
        <Col xs={12}>
          <p className="text-grey">
            LeadSigma sets up an account based on your organization
          </p>
        </Col>
        <Alert
          variant="danger"
          show={!!error}
          onClose={() => setError(null)}
          dismissible
          className="w-100"
        >
          <div>{error}</div>
          <a href={process.env.REACT_APP_LEADSIGMA_SUPPORT}>LeadSigma Support</a>
        </Alert>
        <Col xs={12}>
          <Form onSubmit={submitOrganization}>
            <Form.Group>
              <h5 className="text-black">What is your organization name?</h5>
              <Form.Control
                type="text"
                required
                className="grey-bordered-box form-text-input"
                value={orgName}
                autoComplete="organization"
                onChange={(e) => setOrgName(e.target.value)}
              />
            </Form.Group>
            <Row noGutters className="mb-1">
              <Col xs={12}>
                <Form.Group>
                  <h5 className="text-black">Country</h5>
                  <Form.Control
                    title="Country"
                    id="country-selection"
                    as="select"
                    required
                    className="w-25 grey-bordered-box form-text-input region-dropdown"
                    aria-label="Select country"
                    key="country"
                    defaultValue="US"
                    onChange={(e) => setCountry(e.target.value)}
                  >
                    <option key="blankChoice" hidden value={''}></option>
                    {renderCountries()}
                  </Form.Control>
                </Form.Group>
              </Col>

              <Col xs={12}>
                <Form.Group>
                  <h5 className="text-black">Time Zone</h5>
                  <TimezoneSelect
                    value={timezone}
                    required
                    className="grey-bordered-box form-text-input region-dropdown w-50"
                    onChange={(timezone: ITimezoneOption) =>
                      setTimezone(mapTimeZone(timezone.value))
                    }
                  />
                </Form.Group>
              </Col>
            </Row>

            <Button className="btn-green next-button mt-5 float-right" type="submit">
              <h5 className="text-white align-middle m-0">Next</h5>
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default OrganizationForm;
