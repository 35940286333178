import React from 'react';
import LargeSigmaLogo from '../../components/LargeSigmaLogo';
import SplitPane from '../../layout/SplitPane';
import OrganizationProfileForm from './OrganizationProfileForm';
import LoadingScreen from '../../components/LoadingScreen';

const OrganizationProfile = () => {
  return (
    <LoadingScreen>
      <SplitPane left={<LargeSigmaLogo />} right={<OrganizationProfileForm />} />
    </LoadingScreen>
  );
};

export default OrganizationProfile;
