import React from 'react';
import { useContext } from 'react';
import { useState } from 'react';

const LoadingContextProvider = React.createContext<
  | {
      isLoading: boolean;
      setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
    }
  | undefined
>(undefined);

export const LoadingProvider = ({ children }: { children: React.ReactNode }) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <LoadingContextProvider.Provider value={{ isLoading, setIsLoading }}>
      {children}
    </LoadingContextProvider.Provider>
  );
};

export const useLoadingContext = () => {
  const context = useContext(LoadingContextProvider);
  if (context === undefined) throw new Error('useLoadingContext error');
  return context;
};
