import React from 'react';
import { useState } from 'react';
import SplitPane from '../../layout/SplitPane';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Status, inboundTexts, outboundTexts } from '../../utils/utils';
import { getHubspotCodeCookie } from '../../utils/HubspotCode';
import smallPhone from '../../images/small-phone.svg';
import smallScreen from '../../images/small-screen.svg';
import { useLoadingContext } from '../../utils/LoadingProvider';
import { useEffect } from 'react';
import LoadingScreen from '../../components/LoadingScreen';
import PurpleBlob from './PurpleBlob';

const Success = () => {
  const { isLoading, setIsLoading } = useLoadingContext();
  const [status, setStatus] = useState<Status>('inbound');
  const [texts, setTexts] = useState<string[]>(inboundTexts);

  useEffect(() => {
    setIsLoading(false);
  }, []);

  const handleNext = () => {
    const code: string | undefined = getHubspotCodeCookie();

    if (status === 'inbound') {
      setStatus('outbound');
      setTexts(outboundTexts);
    } else if (code) {
      window.location.href = process.env.REACT_APP_SUPPORT_PAGE as string;
    } else {
      window.location.href = process.env.REACT_APP_WEB_APP as string;
    }
  };

  const PurpleTextBoxes = ({ texts }: { texts: string[] }) => {
    return (
      <Container fluid className="position-relative">
        <PurpleBlob />
        <Row className="m-auto d-flex flex-column purple-text-box-container">
          {texts?.map((text, index) => (
            <Col key={index} className="my-3">
              <div
                className={`purple-text-box ${
                  index % 2 === 0 ? 'mr-auto ml-3' : 'ml-auto mr-3'
                }`}
              >
                <h5 className="text-purple text-center align-middle m-0 px-2 px-lg-0">
                  {text}
                </h5>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    );
  };

  const RightPane = () => {
    return (
      <Container fluid className="w-100 h-100 success-right-pane">
        <Row className="mb-5">
          <Col xs={12}>
            <h2 className="text-black">Thanks for Signing Up!</h2>
          </Col>
          <Col>
            <h5 className="text-grey">
              Learn about how we can help you with inbound and outbound leads
            </h5>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col xs={12} className="my-3">
            <div
              className={`success-image-box ${
                status === 'inbound' ? 'border-green-current' : 'border-black'
              }`}
            >
              <div className="h-100 icon-container">
                <img
                  src={smallPhone}
                  alt="Small Phone Icon"
                  className="small-success-icon"
                />
              </div>
              <div className="text-container">
                <h5 className="text-black">Engage Your Inbound Leads</h5>
                <h6 className="text-grey">
                  Call Slingshot is a super cool feature for you!
                </h6>
              </div>
            </div>
          </Col>
          <Col xs={12} className="my-3">
            <div
              className={`success-image-box ${
                status === 'outbound' ? 'border-green-current' : 'border-black'
              }`}
            >
              <div className="h-100 icon-container">
                <img
                  src={smallScreen}
                  alt="Small Phone Icon"
                  className="small-success-icon screen-icon"
                />
              </div>
              <div className="text-container">
                <h5 className="text-black">Do More with Outbound Leads</h5>
                <h6 className="text-grey">
                  Sequences can take care of your troubles!
                </h6>
              </div>
            </div>
          </Col>
          <Col>
            <Button
              className="btn-green next-button mt-5 float-right"
              onClick={() => handleNext()}
            >
              <h5 className="text-white align-middle m-0">
                {status === 'inbound' ? 'Next' : 'Get Started'}
              </h5>
            </Button>
          </Col>
        </Row>
      </Container>
    );
  };

  return (
    <LoadingScreen>
      <SplitPane left={<PurpleTextBoxes texts={texts} />} right={<RightPane />} />
    </LoadingScreen>
  );
};

export default Success;
