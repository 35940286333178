import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { StripeCardNumberElement } from '@stripe/stripe-js';
import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col, Alert } from 'react-bootstrap';
import { CARD_CVC_OPTIONS, CARD_DATE_OPTIONS, CARD_NUMBER_OPTIONS } from './options';
import visa from '../../../images/visa_logo.png';
import mastercard from '../../../images/mastercard-logo.png';
import americanExpress from '../../../images/american-express-logo.png';
import discover from '../../../images/discover-logo.png';
import { PulseLoader } from 'react-spinners';
import { useHistory } from 'react-router-dom';
import { STARTUP, toJSON, User } from '../../../utils/utils';
import Cookies from 'js-cookie';
import { useLoadingContext } from '../../../utils/LoadingProvider';

const CreditCardForm = () => {
  const { setIsLoading } = useLoadingContext();
  const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();
  const [apiError, setApiError] = useState<string | undefined>();
  const [formError, setFormError] = useState<string | undefined>();
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [isLoadingStripe, setIsLoadingStripe] = useState(false);
  const [TOSIsChecked, setTOSIsChecked] = useState(false);
  const [{ cardHolderName, cardholderZipCode }, setCardFormState] = useState({
    cardHolderName: '',
    cardholderZipCode: '',
  });

  useEffect(() => {
    const isTest = localStorage.getItem('test') == 'true';
    if (isTest) {
      setIsLoading(false);
      setIsLoadingStripe(false);
      setApiError('Loaded as test, load /success');
      return;
    }

    setIsLoading(true);

    const userString = localStorage.getItem('user') ?? '""';
    const user: User = JSON.parse(userString);

    const userOrg = localStorage.getItem('userOrg');

    let body = {};
    if (user && userOrg) {
      body = {
        organization_id: userOrg,
      };
    }

    fetch(`${process.env.REACT_APP_API_BASE}/payment_initiate`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        Accept: 'application/json',
      },
      body: JSON.stringify(body),
    })
      .then(toJSON)
      .then((data) => {
        setClientSecret(data.client_secret);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.body)
          error.json().then((message: any) => {
            Object.values(message).forEach((value) => setApiError(value as string));
          });
        else
          setApiError('There was an error contacting the server. Please try again.');
      });
  }, []);

  const createPaidResource = () => {
    fetch(`${process.env.REACT_APP_API_BASE}/create_paid_resources`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        Accept: 'application/json',
      },
    })
      .then(toJSON)
      .catch((error) =>
        setApiError(
          'There was an error creating your resources. Please contact LeadSigma support.'
        )
      );
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoadingStripe(true);

    if (!stripe || !elements) return;

    if (clientSecret) {
      setIsLoading(true);
      stripe
        .confirmCardSetup(clientSecret, {
          payment_method: {
            card: elements.getElement(CardNumberElement) as StripeCardNumberElement,
            billing_details: {
              name: cardHolderName.trim(),
              address: {
                postal_code: cardholderZipCode,
              },
            },
          },
        })
        .then((res) => {
          setIsLoading(false);
          if (res.error) {
            setApiError(res.error.message);
            setIsLoadingStripe(false);
          }
          if (res.setupIntent) {
            createPaidResource();
            setIsLoadingStripe(false);
            history.push('/success');
          }
        });
    }
  };

  return (
    <Form onSubmit={handleSubmit} className="w-100 ml-1">
      <Alert
        show={apiError !== undefined}
        variant="danger"
        dismissible
        onClose={() => setApiError(undefined)}
      >
        <div>{apiError}</div>
        <a href={process.env.REACT_APP_LEADSIGMA_SUPPORT}>LeadSigma Support</a>
      </Alert>
      <Row noGutters>
        <Col xs={12} className="mt-2 mt-lg-0">
          <h1 className="text-black">Setup Plan</h1>
        </Col>
        <Col xs={12} className="pb-4">
          <h6 className="text-grey">
            You will get to use all our features during the free trial!
          </h6>
        </Col>
        <Col xs={12}>
          <h4 className="text-black">Credit Card Information</h4>
        </Col>
        <Col xs={12} className="mb-2">
          <h6 className="text-grey">You will not be charged right now.</h6>
        </Col>
        <Col xs={12}>
          <Alert
            show={formError !== undefined}
            variant="danger"
            dismissible
            onClose={() => setFormError(undefined)}
          >
            {formError}
          </Alert>
          <Form.Group>
            <Form.Control
              required
              className="grey-bordered-box form-text-input"
              type="text"
              autoComplete="cc-name"
              name="name"
              id="frmNameA"
              placeholder="Cardholder Name"
              value={cardHolderName}
              onChange={(e) =>
                setCardFormState((Prev) => ({
                  ...Prev,
                  cardHolderName: e.target.value,
                }))
              }
            />
          </Form.Group>
        </Col>
        <Col xs={12}>
          <CardNumberElement options={CARD_NUMBER_OPTIONS} />
        </Col>
        <Col xs={4} className="pr-1">
          <CardExpiryElement options={CARD_DATE_OPTIONS} />
        </Col>
        <Col xs={4} className="px-1">
          <CardCvcElement options={CARD_CVC_OPTIONS} />
        </Col>
        <Col xs={4} className="pl-1">
          <Form.Group>
            <Form.Control
              name="ship-zip"
              id="frmZipS"
              autoComplete="postal-code"
              required
              className="grey-bordered-box"
              type="text"
              placeholder="Zip Code"
              value={cardholderZipCode}
              onChange={(e) =>
                setCardFormState((Prev) => ({
                  ...Prev,
                  cardholderZipCode: e.target.value,
                }))
              }
            />
          </Form.Group>
        </Col>
        <Col xs={12} className="mt-1">
          <Row noGutters className="mb-2">
            <Col xs={12} md={9} className="pr-2">
              <p className="text-grey text-small mb-1">
                You can cancel at anytime without being charged within the first 21
                days of your free trial!
              </p>
              <div className="tos-container px-2 py-1">
                <Form.Check
                  checked={TOSIsChecked}
                  onChange={() => setTOSIsChecked((prev) => !prev)}
                  type="checkbox"
                  id="tos-checkbox"
                  className="tos-checkbox"
                />
                <p className="text-white m-0 text-nowrap">
                  I agree to subscription &nbsp;
                </p>
                <br />
                <a
                  href={process.env.REACT_APP_TOS_PAGE}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="m-0 text-nowrap tos-link"
                >
                  Terms of Service
                </a>
              </div>
            </Col>
            <Col xs={12} md={3} className="ml-auto">
              <div className="credit-card-icon-row ml-auto">
                <div className="card-icon-container grey-bordered-box ">
                  <img src={visa} alt="Visa" className="w-100 card-icon" />
                </div>
                <div className="card-icon-container grey-bordered-box ">
                  <img
                    src={mastercard}
                    alt="Master Card"
                    className="w-100 card-icon"
                  />
                </div>
                <div className="card-icon-container grey-bordered-box">
                  <img
                    src={americanExpress}
                    alt="American Express"
                    className="h-100 w-100  card-icon"
                  />
                </div>
                <div className="card-icon-container grey-bordered-box ">
                  <img src={discover} alt="Discover" className="w-100 card-icon" />
                </div>
              </div>
            </Col>
          </Row>
          <Row noGutters className="justify-content-end mt-3 mt-xl-0">
            <Button
              className="btn-green next-button"
              type="submit"
              disabled={!stripe || isLoadingStripe || !TOSIsChecked}
            >
              {isLoadingStripe ? (
                <div className="d-flex justify-content-center align-items-center">
                  <PulseLoader size={20} color={'#907bf0'}></PulseLoader>
                </div>
              ) : (
                <h5 className="text-white align-middle m-0"> Next</h5>
              )}
            </Button>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default CreditCardForm;
