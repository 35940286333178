import React from 'react';
import { Alert, Button, Form, Modal } from 'react-bootstrap';
import LargeSigmaLogo from '../../components/LargeSigmaLogo';
import LoadingScreen from '../../components/LoadingScreen';
import SplitPane from '../../layout/SplitPane';
import SignUpForm from './SignUpForm';
import { getHubspotCodeCookie } from '../../utils/HubspotCode';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';

const Home = (): JSX.Element => {
  const [showHubspot, setShowHubspot] = React.useState(false);
  const [hubCode, setHubCode] = React.useState<string | undefined>();
  const [error, setError] = React.useState<string | null>(null);
  const [organizationId, setOrganizationId] = React.useState<string | null>(null);
  const [JWT, setJWT] = React.useState<string | undefined>(undefined);
  const [isConnecting, setIsConnecting] = React.useState<boolean>(false);
  const [accounts, setAccounts] = React.useState<any[]>([]);
  const history = useHistory();

  React.useEffect(() => {
    const hubspotCode: string | undefined = getHubspotCodeCookie();
    setHubCode(hubspotCode);
    const userJWT = Cookies.get('cognito-jwt');
    setJWT(userJWT);
    if (hubspotCode && userJWT) {
      loadUsers(userJWT);
      setShowHubspot(true);
    }
  }, []);

  const connect = () => {
    setIsConnecting(true);
    fetch(`${process.env.REACT_APP_HUBSPOT_API}/connect`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${JWT}`,
        Accept: 'application/json',
      },
      body: JSON.stringify({
        hubspot_auth_code: hubCode,
        organization_id: organizationId,
      }),
    })
      .then(() => {
        setIsConnecting(false);
        window.location.replace(`${process.env.REACT_APP_SUPPORT_PAGE}`);
      })
      .catch((error) => {
        setIsConnecting(false);
        setError(
          'There was an error saving the Hubspot connection.  Please refresh the page and try again.'
        );
      });
  };

  const loadUsers = (userJWT: string) => {
    fetch(`${process.env.REACT_APP_SALES_API}/users?is_current`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${userJWT}`,
        Accept: 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setAccounts(data.items[0].authorizations.table.organizations);
      })
      .catch((error) => {
        setIsConnecting(false);
        setError(
          'There was an error loading your accounts.  Please refresh the page and try again.'
        );
      });
  };

  return (
    <>
      <Modal show={showHubspot} onHide={() => setShowHubspot(false)}>
        <Modal.Header>
          <Modal.Title>Connect Hubspot</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Alert
            variant="danger"
            show={!!error}
            onClose={() => setError(null)}
            dismissible
            className="w-100"
          >
            <div>{error}</div>
            <a href={process.env.REACT_APP_LEADSIGMA_SUPPORT}>LeadSigma Support</a>
          </Alert>
          <p>Do you want to connect your LeadSigma and Hubspot accounts?</p>
          {accounts.map((account, i) => (
            <Form.Check
              name="account"
              type="radio"
              key={account.id}
              value={account.id}
              label={account.name}
              onChange={(e) => setOrganizationId(e.target.value)}
            />
          ))}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowHubspot(false)}>
            No
          </Button>
          <Button
            disabled={isConnecting}
            className="btn-green next-button"
            onClick={connect}
          >
            {isConnecting ? <ClipLoader /> : 'Yes'}
          </Button>
        </Modal.Footer>
      </Modal>
      <LoadingScreen>
        <SplitPane left={<LargeSigmaLogo showLogin />} right={<SignUpForm />} />
      </LoadingScreen>
    </>
  );
};

export default Home;
