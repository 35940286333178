import React from 'react';
import { Container, Row } from 'react-bootstrap';
import CreditCardForm from './CreditCardForm/CreditCardForm';
import LargeTrialImage from '../../components/LargeTrialImage';
import SplitPane from '../../layout/SplitPane';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import LoadingScreen from '../../components/LoadingScreen';

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`);

const CreditCard = () => {
  const CreditCardFormPane = () => {
    return (
      <Row noGutters>
        <Elements stripe={stripePromise}>
          <CreditCardForm />
        </Elements>
      </Row>
    );
  };

  return (
    <LoadingScreen>
      <Container fluid className="h-100">
        <SplitPane left={<LargeTrialImage />} right={<CreditCardFormPane />} />
      </Container>
    </LoadingScreen>
  );
};

export default CreditCard;
