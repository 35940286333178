import Cookies from 'js-cookie';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Alert, Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import TimezoneSelect, { ITimezoneOption } from 'react-timezone-select';
import { getHubspotCodeCookie } from '../../utils/HubspotCode';
import { useLoadingContext } from '../../utils/LoadingProvider';
import { toJSON } from '../../utils/utils';
import { getCountries } from 'libphonenumber-js';
import { countries } from 'countries-list';

const AddressForm = () => {
  const { setIsLoading } = useLoadingContext();
  const history = useHistory();
  const [addressLineOne, setAddressLineOne] = useState<string>('');
  const [addressLineTwo, setAddressLineTwo] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [region, setRegion] = useState<string>('');
  const [postalCode, setPostalCode] = useState<string>('');
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    setIsLoading(false);
  }, []);

  const submitAddress = (e: React.FormEvent) => {
    setError(null);
    e.preventDefault();

    if (
      new Set([
        addressLineOne.trim(),
        city.trim(),
        region.trim(),
        postalCode.trim(),
      ]).has('')
    ) {
      setError('Address has missing fields');
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    const body: {
      address_line_one: string;
      address_line_two: string;
      city: string;
      region: string;
      postal_code: string;
      country: string;
    } = {
      address_line_one: addressLineOne.trim(),
      address_line_two: addressLineTwo.trim(),
      city: city.trim(),
      region: region.trim(),
      postal_code: postalCode.trim(),
      country: localStorage.getItem('country') ?? ''.trim(),
    };

    fetch(`${process.env.REACT_APP_API_BASE}/setup_address`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
        Accept: 'application/json',
      },
      body: JSON.stringify(body),
    })
      .then(toJSON)
      .then((data) => {
        history.push('/organization-profile');
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.body)
          error.json().then((message: any) => {
            Object.values(message).forEach((value) => setError(value as string));
          });
        else setError('There was an error contacting the server. Please try again.');
      });
  };

  return (
    <Container fluid className="w-100 h-100 d-flex justify-content-start mt-5">
      <Row noGutters>
        <Col xs={12}>
          <h2 className="text-black">Setup Business Address</h2>
        </Col>
        <Col xs={12}>
          <p className="text-grey">
            LeadSigma sets your telecommunications up for success by registering with
            communication agencies on your behalf.
          </p>
          <p>
            <a href="https://leadsigma.zendesk.com/hc/en-us/articles/4408036113435">
              Find out more about A2P
            </a>
          </p>
        </Col>
        <Alert
          variant="danger"
          show={!!error}
          onClose={() => setError(null)}
          dismissible
          className="w-100"
        >
          <div>{error}</div>
          <a href={process.env.REACT_APP_LEADSIGMA_SUPPORT}>LeadSigma Support</a>
        </Alert>
        <Col xs={12}>
          <Form onSubmit={submitAddress}>
            <h5 className="text-black">What is your business address?</h5>
            <Row noGutters className="mb-1">
              <Col xs={12}>
                <Form.Group>
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    as="input"
                    autoComplete="address-line1"
                    key="address_line_one"
                    placeholder="Street address or PO Box"
                    className="grey-bordered-box form-text-input mb-1"
                    required
                    onChange={(e) => setAddressLineOne(e.target.value)}
                  />
                  <Form.Control
                    as="input"
                    key="address_line_two"
                    placeholder="Apt, suite, building, etc"
                    autoComplete="address-line2"
                    className="grey-bordered-box form-text-input"
                    onChange={(e) => setAddressLineTwo(e.target.value)}
                  />
                </Form.Group>
              </Col>

              <Col xs="4">
                <Form.Group className="mr-2">
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    as="input"
                    autoComplete="address-level2"
                    key="city"
                    placeholder=""
                    className="grey-bordered-box form-text-input"
                    required
                    onChange={(e) => setCity(e.target.value)}
                  />
                </Form.Group>
              </Col>

              <Col xs="4">
                <Form.Group className="mr-2">
                  <Form.Label>State</Form.Label>
                  <Form.Control
                    as="input"
                    autoComplete="address-level1"
                    key="state"
                    placeholder=""
                    className="grey-bordered-box form-text-input"
                    required
                    onChange={(e) => setRegion(e.target.value)}
                  />
                </Form.Group>
              </Col>

              <Col xs="4">
                <Form.Group>
                  <Form.Label>Postal Code</Form.Label>
                  <Form.Control
                    as="input"
                    autoComplete="postal-code"
                    key="postal_code"
                    placeholder=""
                    className="grey-bordered-box form-text-input"
                    required
                    onChange={(e) => setPostalCode(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Button className="btn-green next-button mt-5 float-right" type="submit">
              <h5 className="text-white align-middle m-0">Next</h5>
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default AddressForm;
