import React from 'react';
import { useState } from 'react';
import sigmaLogo from '../images/sigma-logo.svg';

const LargeSigmaLogo = ({ showLogin }: { showLogin?: boolean }) => {
  return (
    <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center">
      <img src={sigmaLogo} alt="LeadSigma Logo" className="large-sigma-logo" />
      {showLogin && (
        <h4 className="text-black text-center">
          Already have an account?{' '}
          <a
            href={process.env.REACT_APP_WEB_APP}
            rel="noopener noreferrer"
            className="text-black m-0 text-nowrap existing-account-link"
          >
            Login
          </a>
        </h4>
      )}
    </div>
  );
};

export default LargeSigmaLogo;
